@import 'variables'

.accordion-button
    font-size: $font-size-lg
    font-weight: $font-weight-semibold
    border-top-left-radius: $border-radius
    border-top-right-radius: $border-radius

.accordion-button:focus
    outline: none
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 $accordion-border-color

.accordion-button::after
    margin-right: 6px !important

.accordion-item:not(:last-of-type) .accordion-header.collapsed
    border-bottom: 1px solid $charcoal-100

.accordion-button.hide-toggle::after
    content: none
