@import "variables"

.btn-check + .btn-outline-secondary
    background-color: $white
    border-color: $charcoal-50
    color: $charcoal-900

.btn-check + .btn-outline-secondary:hover
    border-color: $charcoal-100

.btn-check + .btn-outline-secondary.active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $charcoal-900

.btn-check:checked + .btn-outline-secondary
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $charcoal-900

.btn-group .btn-check:checked + .btn-outline-secondary
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $green-700

    &:hover
        border-color: $charcoal-100

// Segmented Control

.btn-group

    & .btn
        font-weight: $font-weight-semibold
        border-width: 1px

    &.segmented-control
        border-radius: $border-radius-lg
        background-color: #7676801F
        padding: 2px !important

        & .btn
            background-color: $charcoal-50
            color: $charcoal-600
            border-radius: 9px !important

            &:not(:last-child)
                margin: 0 2px 0 0

            &:last-child
                margin: 0

            &:hover
                background-color: $charcoal-100
                color: $charcoal-600
                border: 1px solid $charcoal-50

        & .btn-sm
            padding: 0.25rem 0.5rem
            font-size: $font-size-base
            line-height: $line-height-base
            border-radius: $border-radius-md

.segmented-control input.btn-check:checked + .btn
    background-color: white
    color: black
    border: 0.5px solid rgba(0, 0, 0, 0.04)
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04)

label.btn-outline-secondary:has(input[type="radio"]:checked)
    background-color: $green-25
    border-color: $green-700
