.ql-toolbar.ql-snow
    font-family: "Source Sans Pro", sans-serif

.ql-snow .ql-picker
    font-weight: 600

.ql-container
    font-family: "Source Sans Pro", sans-serif
    font-size: 14px

.ql-editor.ql-blank::before
    font-style: normal
