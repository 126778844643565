@import 'variables'

.breadcrumb-item
    font-size: $font-size-base
    font-weight: $font-weight-semibold

    a
        color: $charcoal-400
        text-decoration: none

        &:hover
            text-decoration: underline

