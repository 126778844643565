$blue-900: #1E5E85
$blue-800: #297DA7
$blue-700: #2C8EBA
$blue-600: #35A1CD
$blue-500: #3DAFDA
$blue-400: #4EBBDE
$blue-300: #66C7E2
$blue-200: #8CD7EA
$blue-100: #B7E7F2
$blue-50: #E3F6F9
$blue-25: #F1FBFC

$charcoal-900: #2A3134
$charcoal-800: #3B464A
$charcoal-700: #49595F
$charcoal-600: #586D74
$charcoal-500: #647C85
$charcoal-400: #7C8F96
$charcoal-300: #94A3A8
$charcoal-200: #B5BDC0
$charcoal-100: #D4D7D7
$charcoal-50: #EEEEEE
$charcoal-25: #F7F7F7

$deep-blue-900: #00283D
$deep-blue-800: #133C55
$deep-blue-700: #224E6B
$deep-blue-600: #316181
$deep-blue-500: #3E6F92
$deep-blue-400: #5A84A5
$deep-blue-300: #749AB8
$deep-blue-200: #97B7D2
$deep-blue-100: #B7D5ED
$deep-blue-50: #DBEFFF
$deep-blue-25: #EDF7FF

$green: #219653
$green-900: #00582A
$green-800: #0C773E
$green-700: #158849
$green-600: #1F9A55
$green-500: #27A95E
$green-400: #4EB576
$green-300: #6FC28D
$green-200: #99D3AD
$green-100: #C1E4CD
$green-50: #E5F4EB
$green-25: #F2F9F5

$indigo-900: #151B88
$indigo-800: #252E9C
$indigo-700: #2E39A8
$indigo-600: #3743B4
$indigo-500: #3D4BBE
$indigo-400: #5A66C8
$indigo-300: #7882D2
$indigo-200: #9FA5DF
$indigo-100: #C5C8EC
$indigo-50: #E8E9F7
$indigo-25: #F3F4FB

$purple-900: #5C2583
$purple-800: #6D2E9B
$purple-700: #7D3DB0
$purple-600: #8D4DC5
$purple-500: #9C5DDA
$purple-400: #AC6DEE
$purple-300: #BF7FF2
$purple-200: #D8A1F5
$purple-100: #F1C3F8
$purple-50: #FFE6FC
$purple-25: #F7F3FE

$red-900: #B01624
$red-800: #BF2230
$red-700: #CC2937
$red-600: #DE333D
$red-500: #ED3C3F
$red-400: #E84F57
$red-300: #DE7077
$red-200: #E9989D
$red-100: #FBCCD3
$red-50: #FDEBEE
$red-25: #FEF5F7

$teal-900: #104C45
$teal-800: #136862
$teal-700: #137872
$teal-600: #158882
$teal-500: #179590
$teal-400: #2EA5A2
$teal-300: #51B5B3
$teal-200: #82CACA
$teal-100: #B3DEDE
$teal-50: #E1F2F2
$teal-25: #F0F9F9

$yellow-900: #B08A1F
$yellow-800: #C09E2A
$yellow-700: #C8A72D
$yellow-600: #D0B030
$yellow-500: #D6B933
$yellow-400: #DCC13A
$yellow-300: #E2C940
$yellow-200: #E8D14A
$yellow-100: #EEDB5A
$yellow-50: #F3E56B
$yellow-25: #F8F0E0

$orange-900: #CD6233
$orange-800: #DB7637
$orange-700: #E3843A
$orange-600: #EA923F
$orange-500: #EF9C43
$orange-400: #F2AA4F
$orange-300: #F5B869
$orange-200: #F7CC91
$orange-100: #FAE0BC
$orange-50: #FDF3E4
$orange-25: #FEF9F1
