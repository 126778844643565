@import 'variables'

span.feedback-badge
    border-radius: $border-radius-sm
    font-size: $font-size-base
    line-height: $line-height-base
    padding: 0 0.5rem
    font-weight: $font-weight-semibold
    text-wrap: nowrap

    &.badge-very-poor
        background-color: $red-25
        color: $red-700

    &.badge-poor
        background-color: $orange-25
        color: $orange-700
    &.badge-fair
        background-color: $white
        color: $charcoal-700
    &.badge-good
        background-color: $green-25
        color: $green-700
    &.badge-excellent
        background-color: $blue-25
        color: $blue-700

span.badge-secondary
    border-radius: $border-radius-lg
    font-size: $font-size-sm
    line-height: $line-height-sm
    padding: 2px 0.5rem
    font-weight: $font-weight-semibold
    background-color: $charcoal-50
    color: $charcoal-900

span.badge-large
    border-radius: 6px
    font-size: $font-size-base
    line-height: $line-height-base
    padding: 0 7px
    font-weight: $font-weight-semibold
    background-color: $charcoal-25
    color: $charcoal-900

span.badge-medium
    border-radius: 5px
    font-size: $font-size-md
    line-height: $line-height-md
    padding: 0 6px 0 6px
    font-weight: $font-weight-semibold
    background-color: $charcoal-25
    color: $charcoal-900

span.badge-small
    border-radius: 4px
    font-size: $font-size-sm
    line-height: $line-height-sm
    padding: 0 6px
    font-weight: $font-weight-semibold
    background-color: $charcoal-25
    color: $charcoal-900

span.badge-xs, span.badge-extra-small
    border-radius: 3px
    font-size: $font-size-xs
    line-height: $line-height-xs
    padding: 0 4px
    font-weight: $font-weight-semibold
    background-color: $charcoal-25
    color: $charcoal-900

span.badge-small.danger
    background-color: $red-100
    color: $red-900

span.badge-small.success
    background-color: $green-100
    color: $green-900
