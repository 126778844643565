@import "variables"

@media (max-width: 576px)
    ngb-datepicker
        inset: $modal-dialog-margin auto auto $modal-dialog-margin !important
        left: 50% !important
        transform: translateX(-50%) !important

    .ngb-dp-months
        flex-flow: row wrap !important

ngb-datepicker
    box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.05)

.ngb-dp-header
    background-color: $white !important
    padding: 16px 16px 0 16px !important
    border-radius: $border-radius-lg !important

.ngb-dp-navigation-select > .form-select
    height: auto !important
    font-size: 1rem !important
    padding: 0.375rem 2.25rem 0.375rem 0.75rem !important

.ngb-dp-month-name
    background-color: $white !important
    font-size: $font-size-lg !important
    line-height: $line-height-lg !important
    font-weight: 600 !important
    color: $primary
    order: 0
    flex: 1 1 auto !important
    text-align: start !important

.ngb-dp-arrow
    order: 1
    flex: 0 0 auto !important
    width: auto !important
    height: auto !important

.ngb-dp-navigation-chevron
    color: $charcoal-900
    border-width: 0.15rem 0.15rem 0 0 !important
    padding: 0.25rem !important
    margin: 8px 8px !important
    width: auto !important
    height: auto !important

.ngb-dp-arrow-btn
    background-color: $white !important
    padding: 4px 6px 0 6px !important
    border: 1px solid $charcoal-50 !important
    margin: 0 !important

    &:hover
        background-color: $charcoal-25 !important
        border: 1px solid $charcoal-100 !important

    &:active
        background-color: $charcoal-50 !important
        border: 1px solid $charcoal-50 !important

ngb-datepicker-navigation
    gap: 0.5rem !important

ngb-datepicker-navigation-select > .form-select:first-child
    margin-right: 0.5rem !important

.ngb-dp-months
    flex-flow: row nowrap
    justify-content: flex-start

.ngb-dp-month
    padding: 0.8rem
    flex: 1 0 auto

.ngb-dp-month .ngb-dp-week:last-child
    padding-bottom: 0 !important

@media (max-width: 576px)
    .ngb-dp-month:not(:last-child)
        padding-bottom: 0 !important

@media (min-width: 576px)
    .ngb-dp-month:not(:last-child)
        padding-right: 0 !important

.ngb-dp-weekdays
    border-bottom: none !important
    background-color: $charcoal-25 !important
    border-radius: $border-radius-md !important
    margin-bottom: 8px !important

.ngb-dp-weekday, .ngb-dp-week-number
    color: $charcoal-300 !important
    font-style: normal !important
    line-height: 32px !important

.ngb-dp-weekday
    font-size: $font-size-base !important
    font-weight: 600 !important
    color: $charcoal-900 !important
    width: 38px !important
    height: 32px !important

.ngb-dp-day
    width: 38px !important
    height: 38px !important

.custom-day
    padding: 0.3rem !important

[ngbDatepickerDayView]
    border-radius: 10px !important
    font-size: $font-size-lg !important
    width: 34px !important
    height: 34px !important
    line-height: 34px !important

    &:active
        background-color: $charcoal-50 !important
        font-size: $font-size-lg * 1.1 !important
        font-weight: $font-weight-semibold !important

.ngb-dp-today > [ngbDatepickerDayView]
    background-color: $charcoal-50
