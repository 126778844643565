@import 'variables'

.nav-link.active > img, .nav-link:active > img
    filter: invert(14%) sepia(76%) saturate(101%) hue-rotate(153deg) brightness(50%) contrast(90%)

.nav-pills.tabbed .nav-link
    color: $charcoal-600
    font-size: $font-size-lg
    font-weight: 600
    padding: 0.875rem 1.5rem

.nav-pills.tabbed .nav-link:hover
    color: $charcoal-800

.nav-pills.tabbed .nav-link.active
    background-color: $white
    padding-bottom: 0

.nav-pills.tabbed .nav-link.active
    color: $green-700

.nav-pills.tabbed .nav-link-secondary.active
    color: $charcoal-800

.nav-pills.tabbed .nav-link.active::after
    content: ""
    border-bottom-style: solid
    border-bottom-width: 3px
    display: block
    margin-left: -1.5rem
    margin-right: -1.5rem
    padding-bottom: calc(1rem - 3px)

.nav-pills.tabbed .nav-link.active::after
    border-bottom-color: $green-700

.nav-pills.tabbed .nav-link-secondary.active::after
    border-bottom-color: $charcoal-700

@media (max-width: 1200px)
    .nav-pills.tabbed .nav-link
        padding: 0.625rem 0.75rem

    .nav-pills.tabbed .nav-link.active::after
        margin-left: -0.75rem
        margin-right: -0.75rem
        padding-bottom: calc(0.75rem - 3px)
