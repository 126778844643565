@import 'colours'
@import 'theme-colours'

$font-size-root: 14px
$font-family-base: "Source Sans Pro", sans-serif

$accordion-border-width: 1px
$accordion-border-color: $charcoal-50
$accordion-button-active-bg: white
$accordion-button-active-color: $charcoal-900
$accordion-button-active-icon: url("/assets/caret/normal.svg")
$accordion-button-bg: $charcoal-25
$accordion-button-color: $charcoal-600
$accordion-button-icon: url("/assets/caret/normal.svg")
$accordion-button-padding-x: 16px
$accordion-button-padding-y: 10px
$accordion-icon-width: 24px
$accordion-padding-x: 0
$accordion-padding-y: 0
$accordion-border-radius: 0.625rem

$alert-bg-scale: -10%
$alert-border-radius: 0.625rem
$alert-border-scale: -70%
$alert-border-width: 1px
$alert-color-scale: 50%
$alert-margin-bottom: 0
$alert-padding-x: 16px
$alert-padding-y: 8px

$breadcrumb-active-color: $charcoal-700
$breadcrumb-divider-color: $charcoal-400
$breadcrumb-font-size: 1rem

$popover-max-width: 500px

$font-size-xxs: 0.75rem
$font-size-xs: 0.8125rem
$font-size-sm: 0.875rem
$font-size-md: 1rem
$font-size-lg: 1.125rem
$font-size-xl: 1.25rem
$font-size-display-small: 1.5rem
$font-sizes: (1: $font-size-xs, 2: $font-size-sm, 3: $font-size-sm, 4: $font-size-md, 5: $font-size-lg, 6: $font-size-xl)

$form-text-font-size: $font-size-sm
$form-text-color: $charcoal-700

$line-height-xs: 1rem
$line-height-sm: 1.125rem
$line-height-md: 1.25rem
$line-height-lg: 1.5rem
$line-height-xl: 1.75rem
$line-height-display-small: 2rem
//$line-height-xs: 1rem
//$line-height-sm: 1.125rem
//$line-height-md: 1.25rem
//$line-height-lg: 1.5rem
//$line-height-xl: 1.75rem
//$line-height-display-small: 2rem

//$line-height-base: 1.5rem
//$line-height-xs: 1.125rem

$border-color: $charcoal-50
$border-radius-xs: 0.25rem
$border-radius-sm: 0.375rem
$border-radius-md: 0.5rem
$border-radius-lg: 0.625rem
$border-radius-xl: 0.75rem
$border-radius-xxl: 1rem
$border-radius: $border-radius-xl

$badge-border-radius: $border-radius-sm
$badge-font-size: $font-size-xs
$badge-font-weight: 600
$badge-padding-x: 0.5rem
$badge-padding-y: 0.25rem

$btn-border-width: 1px
$btn-border-radius-xs: 0.5rem
$btn-border-radius-sm: $border-radius
$btn-border-radius: $border-radius
$btn-border-radius-lg: $border-radius
$btn-line-height: 1.5rem
$btn-padding-x-xs: 0.5rem
$btn-padding-x-sm: 0.6875rem
$btn-padding-x: 0.875rem
$btn-padding-x-lg: 1.5rem
$btn-padding-y-xs: 0.2rem
$btn-padding-y-sm: 0.375rem
$btn-padding-y: 0.429rem
$btn-padding-y-lg: 0.5rem
$btn-font-size-sm: 1rem
$btn-font-size: 1rem
$btn-font-size-lg: 1.125rem

$card-border-color: $charcoal-50

$modal-header-padding: 0.75rem 1.25rem
$modal-footer-padding: 0.5rem 1.25rem
$modal-content-border-radius: $border-radius-xxl
$modal-footer-bg: $charcoal-25
$modal-footer-border-width: 0
$modal-inner-padding: 0.75rem
$modal-lg: 582px
$modal-dialog-margin: 1rem
$modal-transition: 0.2s

$input-border-color: $charcoal-50
$input-border-radius-sm: 0.625rem
$input-border-radius: 0.625rem
$input-border-radius-lg: 0.625rem
$input-font-weight: 600
$input-font-size-lg: 1rem
$input-padding-y: 0.5rem
$input-padding-y-lg: 1rem
$input-placeholder-color: $charcoal-400

$list-group-color: $charcoal-900

$spinner-border-width: 2px

$table-hover-bg: $charcoal-25
$table-th-font-weight: 600

$toast-header-color: $black
$toast-color: $charcoal-900
$toast-border-radius: $border-radius
$toast-border-width: 2px
$toast-padding-x: 0.75rem
$toast-padding-y: 0.425rem

$nav-pills-border-radius: $border-radius-lg
$nav-pills-link-active-color: $primary
$nav-pills-link-active-bg: $green-25
$nav-link-padding-x: 0.75rem
$nav-link-padding-y: 0.5rem
$nav-link-font-weight: 600
$nav-link-color: $charcoal-700
$nav-link-hover-color: $charcoal-800

$offcanvas-backdrop-opacity: 0.25
$offcanvas-box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.05)
$offcanvas-transition-duration: 0.2s

$h4-font-size: 1.25rem
$h4-line-height: 1.75rem
$headings-color: $charcoal-900

$tooltip-max-width: 300px
$tooltip-opacity: 1
$tooltip-border-radius: $border-radius-md

@import 'bootstrap/scss/functions'
@import 'bootstrap/scss/variables'
@import 'bootstrap/scss/mixins'
