@import "variables"

.form-control

    &:active, &:focus
        border: 1px solid $green-700
        box-shadow: 0 0 0 3px $green-200
        border-radius: 10px

.form-switch
    padding: 0

.form-switch .form-check-input
    width: 48px
    height: 30px
    margin: 0
    border-radius: 100px

.form-switch .form-label
    margin-top: 0.3rem

.input-plain
    border: none
    border-radius: 0
    box-shadow: none
    padding: 0
    background: none
    outline: none
    &:focus
        outline: none

.input-secondary
    border: 1px solid $charcoal-50
    border-radius: 0
    box-shadow: none
    padding: 0
    background: none
    outline: none
    &:focus
        outline: none

    &:active, &:focus
        border: 1px solid $charcoal-200
        box-shadow: none
        border-radius: 0

.input-lg
    font-size: $font-size-lg
    line-height: $line-height-lg
