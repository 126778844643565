@import 'variables'

.dropdown-toggle
    color: $deep-blue-900
    border-color: $charcoal-50
    border-radius: $border-radius-lg

    &.btn-info
        color: $white
        background-color: $teal-700
        border-color: $teal-700

    &.dropdown-toggle-split
        padding-left: calc(0.25rem - #{$btn-border-width})
        flex-grow: 0

.dropdown-toggle

    &:hover
        background-color: $charcoal-25
        border-color: $charcoal-100
        color: $deep-blue-900

    &.btn-info:hover
        background-color: $teal-500
        border-color: $teal-500

    &.btn-info:active
        background-color: $teal-800
        border-color: $teal-800

.dropdown-toggle:first-child

    &:active
        background-color: $charcoal-50
        border-color: $charcoal-50
        color: $deep-blue-900

    &.btn-info:active
        background-color: $teal-800
        border-color: $teal-800

.dropdown-toggle

    &::after
        content: url(/assets/dropdown_arrows.svg)
        border: none
        width: 1.5rem
        height: 1.5rem
        align-self: baseline
        vertical-align: bottom
        margin-left: auto

    &.btn-info::after
        content: url(/assets/dropdown_arrows_white.svg)

.dropdown-toggle.compact
    padding-left: calc(0.75rem - #{$btn-border-width})
    padding-right: calc(0.75rem - #{$btn-border-width})

.btn-sm.dropdown-toggle.compact
    padding-left: calc(0.5rem - #{$btn-border-width})
    padding-right: calc(0.5rem - #{$btn-border-width})

.dropdown-toggle.compact::after
    content: none

.dropdown-toggle.btn
    border-radius: $border-radius

.dropdown-toggle.btn .location
    content: url(/assets/location.svg)
    align-self: center

.dropdown-toggle.btn:hover .location
    content: url(/assets/location_hover.svg)

.dropdown-menu
    border-color: $charcoal-50
    border-width: 1px
    border-style: solid
    border-radius: $border-radius
    padding: 0.5rem
    box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.05)

.dropdown-menu-inline
    min-width: 0 !important

.dropdown-item:not(:last-child)
    margin-bottom: 0.5rem

.dropdown-item
    color: $charcoal-700
    border: 1px solid $white
    padding: 0.25rem 0.5rem
    border-radius: $border-radius-lg

.dropdown-item:hover
    background-color: $charcoal-25
    border: 1px solid $charcoal-50

.dropdown-item:active, .dropdown-item.active
    background-color: $charcoal-50
    border: 1px solid $charcoal-50
    color: $deep-blue-900

.bi-circle
    color: $charcoal-500
