@import "variables"

.toast-header
    border-bottom: 0
    background-color: inherit
    padding-bottom: calc(#{$toast-padding-y} / 4)

    .btn-close
        margin-right: calc(#{$toast-padding-x} / -4)

.toast-body
    line-height: $line-height-base
    padding: 0 calc(#{$toast-padding-y} / 4) $toast-padding-x calc(#{$toast-padding-y} * 1.5)
