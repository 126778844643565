.table.table-form
    margin-bottom: 0

    & tbody tr:last-child
        border-bottom: 0

    & thead tr th, & tbody tr td
        padding: 0.5rem

    & thead tr th:first-child, & tbody tr td:first-child
        padding: 0.5rem 0.5rem 0.5rem 0

    & thead tr th:last-child, & tbody tr td:last-child
        padding: 0.5rem 0 0.5rem 0.5rem
