@import 'variables'

.btn-navigation
    background-color: transparent
    border-radius: $border-radius-lg
    color: $charcoal-500
    font-size: $font-size-base
    line-height: $line-height-base
    padding: calc(6px - #{$btn-border-width}) calc(8px - #{$btn-border-width})
    text-align: start

.btn-navigation:hover
    background-color: $charcoal-25
    border: 1px solid $charcoal-50
    color: $charcoal-500

.btn-navigation:first-child:active
    background-color: $charcoal-50
    border: 1px solid $charcoal-50
    color: $charcoal-500
