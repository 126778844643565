@import 'variables'

@media (min-width: 768px)
    .modal-md-block
        max-width: calc(100% - var(--bs-modal-margin) * 2)
        aspect-ratio: 3.4 / 2

.modal-header h4
    font-weight: $font-weight-bold
    font-size: $font-size-display-small
    line-height: $line-height-display-small

.modal-content .component-host-scrollable
    display: contents

.modal-h-fixed .modal-content
    height: min(100vh - $modal-dialog-margin-y-sm-up, 576px)

.modal-xl-max-width .modal-content
    max-width: 1140px
