@import "variables"

.pointer-events-none
    pointer-events: none

.fs-7
    font-size: $font-size-xs
    line-height: $line-height-xs

.min-w-0
    min-width: 0

.min-h-0
    min-height: 0

.two-column-grid
    grid-row-gap: 0.4rem
    grid-column-gap: 0.8rem
    grid-template-columns: calc(50% - 0.4rem) calc(50% - 0.4rem)

.three-column-grid-sm-two-column-grid
    @extend .two-column-grid
    @media (min-width: 576px)
        grid-template-columns: calc(33.33% - 0.55rem) calc(33.33% - 0.55rem) calc(33.33% - 0.55rem)

.grid-md-fill-equally
    @media (min-width: 768px)
        grid-auto-flow: column
        grid-auto-columns: 1fr

.three-column-grid-md-one-column-grid
    @media (min-width: 768px)
        grid-template-columns: repeat(3, 1fr)

.four-column-grid-md-one-column-grid
    @media (min-width: 768px)
        grid-template-columns: repeat(4, 1fr)

.btn-not-group-md
    @media (min-width: 768px)
        border-radius: var(--bs-border-radius)

.btn-not-group-md > .btn
    @media (min-width: 768px)
        border-top-right-radius: $btn-border-radius !important
        border-bottom-right-radius: $btn-border-radius !important
        border-top-left-radius: $btn-border-radius !important
        border-bottom-left-radius: $btn-border-radius !important

.hidden-if-empty:empty
    display: none

.mobile.landscape .d-mobile-landscape-none
    display: none
