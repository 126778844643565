@import 'colours'

$primary: rgb(21, 136, 72)
$body-color: $charcoal-900
$secondary: $charcoal-700
$secondary-text-emphasis: $charcoal-300
$body-secondary-color: $charcoal-700
$dark-bg-subtle: $charcoal-50
$text-muted: $charcoal-600
$light: $charcoal-25
$light-bg-subtle: $charcoal-25
$light-bg-subtle-dark: $charcoal-50
$light-text-emphasis: $charcoal-800
$light-text-emphasis-dark: $charcoal-900
$info-border-subtle: $blue-100
$info-bg-subtle: $blue-25
$info-bg-subtle-dark: $blue-100
$info-text-emphasis: $blue-800
$info-text-emphasis-dark: $blue-900
$warning-bg-subtle: $orange-25
$warning-bg-subtle-dark: $orange-100
$info: $blue-800
$warning: $orange-800
$danger: rgb(218, 58, 62)
$grey: rgb(158, 11, 162)
$black: rgb(47, 47, 65)
$white_offset: rgb(246, 247, 255)
$dark: $charcoal-900

$highlight_colour: rgb(32, 186, 57) !important
