@keyframes pulse
    0%
        opacity: 0.7
    50%
        opacity: 1
    100%
        opacity: 0.7

.pulse
    animation: pulse 2s infinite ease-in-out
