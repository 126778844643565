@import 'variables'

button
    touch-action: manipulation

.dropdown-menu .btn
    font-size: $btn-font-size-lg
    line-height: $line-height-lg
    font-weight: $font-weight-normal

    &.btn-outline-secondary
        color: $charcoal-700

.btn-close
    opacity: 0.7

button.btn
    font-weight: $font-weight-semibold
    border-width: 1px

.btn.disabled
    opacity: 1

.btn-xs
    font-size: $font-size-xs
    line-height: $line-height-xs
    padding: $btn-padding-y-xs $btn-padding-x-xs
    border-radius: $btn-border-radius-xs

// Primary

.btn-primary
    background-color: $green-700
    color: $white

.btn-primary:hover
    background-color: $green-600
    color: $white

.btn-primary:focus
    background-color: $green-700
    color: $white

.btn-primary:first-child:active, .btn-primary.active
    background-color: $green-900
    color: $white

.btn-primary:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300

.btn-primary.pressed
    background-color: $green-25
    border-color: $green-700
    color: $green-700

.btn-primary.pressed:active, .btn-primary.pressed.active
    background-color: $green-400
    border-color: $green-700
    color: $white

.btn-primary.pressed:hover
    background-color: $green-50
    border-color: $green-700
    color: $green-700

.btn-primary.pressed:focus
    background-color: $green-25
    border-color: $green-700
    color: $green-700

// Info

.btn-info
    background-color: $teal-700
    border-color: $teal-700
    color: $white

.btn-info:hover
    background-color: $teal-500
    border-color: $teal-500
    color: $white

.btn-info:focus
    background-color: $teal-700
    border-color: $teal-700
    color: $white

.btn-info:first-child:active, .btn-info.active
    background-color: $teal-800
    border-color: $teal-800
    color: $white

.btn-info:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300

.btn-outline-info
    background-color: $white
    border-color: $charcoal-50
    color: $teal-700

.btn-outline-info:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $teal-700

.btn-outline-info:focus
    background-color: $white
    border-color: $charcoal-200
    color: $teal-700

.btn-outline-info:first-child:active, .btn-outline-info.active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $teal-700

.btn-outline-info:disabled
    background-color: $charcoal-25
    border-color: $charcoal-25
    color: $charcoal-700

// Primary

.btn-outline-primary
    background-color: $white
    border-color: $charcoal-50
    color: $green-700

.btn-outline-primary:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $green-700

.btn-outline-primary:hover.editable
    background-color: $green-25
    border-color: $green-25
    color: $green-700
    display: flex
    flex-direction: row
    justify-content: center
    gap: 0.5rem

.btn-outline-primary:focus
    background-color: $white
    border-color: $charcoal-200
    color: $green-700

.btn-outline-primary:first-child:active,
.btn-outline-primary.active,
.btn-outline-primary:active,
:not(.btn-check) + .btn-outline-primary:active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $green-900

.btn-check + .btn-outline-primary:hover
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $green-700

.btn-check:checked + .btn-outline-primary:hover
    background-color: $green-600
    border-color: $green-600
    color: $white

// Secondary

.btn-outline-secondary
    background-color: $white
    border-color: $charcoal-50
    color: $charcoal-900

.btn-outline-secondary:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $charcoal-900

.btn-outline-secondary:hover.editable
    background-color: $green-25
    border-color: $green-25
    color: $green-700
    display: flex
    flex-direction: row
    justify-content: center
    gap: 0.5rem

.btn-outline-secondary:focus
    background-color: $white
    border-color: $charcoal-200
    color: $charcoal-900

.btn-outline-secondary:first-child:active,
:not(.btn-check) + .btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary:active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $charcoal-900

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled
    background-color: $charcoal-25
    border-color: $charcoal-25
    color: $charcoal-700

.btn-outline-secondary.show
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $charcoal-700

.btn-outline-secondary.pill
    background-color: $green-25
    border-color: $green-700
    color: $green-700

.btn-outline-secondary.pressed
    background-color: $green-25
    border-color: $green-700
    color: $green-700

.btn-outline-secondary.pressed:active, .btn-outline-secondary.pressed.active
    background-color: $green-400
    border-color: $green-700
    color: $white

.btn-outline-secondary.pressed:hover
    background-color: $green-50
    border-color: $green-700
    color: $green-700

.btn-outline-secondary.pressed:focus
    background-color: $green-25
    border-color: $green-700
    color: $green-700

// Danger

.btn-outline-danger
    background-color: $white
    border-color: $charcoal-50
    color: $red-700

.btn-outline-danger:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $red-700

.btn-outline-danger:focus
    background-color: $white
    border-color: $red-200
    color: $red-700

.btn-outline-danger:first-child:active,
.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-check:checked + .btn-outline-danger,
:not(.btn-check) + .btn-outline-danger:active
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $red-900

.btn-outline-danger:first-child:active, .btn-outline-danger.active
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $red-900

.btn-outline-danger:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300

// Plain Dashed

.btn-outline-dashed
    outline: 2px dashed $charcoal-100
    outline-offset: -2px
    padding: 0.5rem 1rem
    border: none

.btn-outline-dashed:hover
    outline: 2px dashed $charcoal-200

.btn-outline-dashed:focus
    outline: 2px dashed $charcoal-300 !important

.btn-outline-dashed:disabled
    outline: 2px dashed $charcoal-100 !important

.btn-outline-dashed:active
    outline: 2px dashed $charcoal-400 !important
    background-color: $charcoal-50

.btn.redacted
    position: relative
    white-space: normal
    &:after
        background: linear-gradient(90deg, $charcoal-100, $charcoal-50, $charcoal-100)
        border-radius: $border-radius-sm
        content: " "
        width: calc(100% - #{$btn-padding-x-sm * 2})
        height: calc(100% - #{$btn-padding-y-sm * 2})
        top: $btn-padding-y-sm
        left: $btn-padding-x-sm
        position: absolute

.btn-outline-warning
    background-color: $orange-25
    border-color: $orange-200
    color: $body-color

.btn-outline-warning:hover
    background-color: $orange-50
    border-color: $orange-300

.btn-outline-warning:focus
    background-color: $orange-25
    border-color: $orange-400

.btn-outline-warning:active, .btn-outline-warning:first-child:active, .btn-outline-warning:not(.btn-check) + .btn-outline-warning:active
    background-color: $orange-100
    border-color: $orange-500

.btn-outline-warning:disabled
    background-color: $orange-25
    border-color: $orange-200
    color: $charcoal-300

.btn-outline-info
    background-color: $teal-25
    border-color: $teal-200
    color: $body-color

.btn-outline-info:hover
    background-color: $teal-50
    border-color: $teal-300

.btn-outline-info:focus
    background-color: $teal-25
    border-color: $teal-400

.btn-outline-info:active, .btn-outline-info:first-child:active, .btn-outline-info:not(.btn-check) + .btn-outline-info:active
    background-color: $teal-100
    border-color: $teal-500

.btn-outline-info:disabled
    background-color: $teal-25
    border-color: $teal-200
    color: $charcoal-300
